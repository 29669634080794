import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import BibliotecaAntofagastaPic from "../images/biblioAntof.jpg"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Wrapper from "../components/wrapper"
import SectionTitle from "../components/sectionTitle"
import text from "../constants/labels.json"
import PlaceCard from "../components/placeCard"

const Lenguaje = () => (
  <Layout>
    <Seo title="Lenguaje" />
    <Background>
      <Wrapper>
        <Container>
          <SectionTitle
            title={text.labels.pages.language}
            textColor="#26272d"
          />
          <PlaceTitle>Estos son los lugares a visitar:</PlaceTitle>
          <SectionContainer>
            <InfoSection>
              <PlaceCard
                picSrc={BibliotecaAntofagastaPic}
                cardColor="#5a71eb"
                title={text.labels.info.places.language.places.biblio.title}
                placeInfo={
                  text.labels.info.places.language.places.biblio.description
                }
                placeLink={text.labels.info.places.language.places.biblio.link}
                buttonTextColor="#f5f4f4"
                buttonBgColor="#D964A6"
                buttonHoverColor="#eb7cbb"
                buttonActiveColor="#cf5d9d"
                isRight={false}
              />
              <StyledLink to="/">Volver a la página principal</StyledLink>
            </InfoSection>
          </SectionContainer>
        </Container>
      </Wrapper>
    </Background>
  </Layout>
)

const Container = styled.div`
  margin-top: 200px;
  @media screen and (max-width: 770px) {
    margin-top: 50px;
  }
`
const PlaceTitle = styled.h3`
  margin-top: 45px;
  text-align: center;
`
const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;
  font-size: 1.15rem;
  line-height: 2rem;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`
const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`
const StyledLink = styled(Link)`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  text-decoration: none;
`
const Background = styled.div`
  background-color: #c2ccfc;
  padding-top: 50px;
`

export default Lenguaje
